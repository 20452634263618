export const useMembersArea = () => {
  const route = useRoute()
  const event = useRequestEvent()

  const path = computed(() => {
    if (import.meta.server && event) {
      return event.node.req.url || ""
    }
    return route.path
  })

  const inMembersArea = computed(() => {
    return path.value.startsWith("/members")
  })

  const inTourArea = computed(() => {
    return !inMembersArea.value
  })

  return { inMembersArea, inTourArea }
}
